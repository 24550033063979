import React from "react"
import PropTypes from "prop-types"
import { FaCheck } from 'react-icons/fa'
import { useSelector } from "react-redux"

import { isDefined } from "../../../../../utils/data"

const YourOrder = ({ ticket, options, total }) => {
    const { coordinator } = useSelector(({ groupReducer }) => groupReducer)

    return (
        <div className={"sr-card summary-block your-order"}>
            <div className={"summary-header"}>
                Your order :
            </div>
            <hr />
            <div className={"summary-body"}>
                {ticket !== null ?
                    <div className={"row ticket"}>
                        <div className={"col-1 d-none d-md-block"}>
                            <FaCheck />
                        </div>
                        <div className={"col-8 summary-name"}>
                            {ticket.packages_name}
                        </div>
                        <div className={"col-3 prices"}>
                            {ticket.packages_price}€
                        </div>
                    </div>
                    : ""}

            </div>
            <hr />
            <div className={"summary-footer"}>
                {isDefined(coordinator) ? isDefined(coordinator.group_payments) ?
                    parseFloat(coordinator.group_payments) > 0 && parseFloat(coordinator.group_payments) < parseFloat(total) ?
                        <div className={"row vat"}>
                            <div className={"col-lg-6 align-left"}>
                                Already pay
                            </div>
                            <div className={"col-lg-6 prices"}>
                                ( {parseFloat(coordinator.group_payments)} € )
                            </div>
                        </div>
                        : "" : "" : ""}
                <div className={"row total"}>
                    <div className={"col-lg-6 align-left"}>
                        Total
                    </div>
                    <div className={"col-lg-6 prices"}>
                        {isDefined(coordinator) ? parseFloat(total) - (parseFloat(coordinator.group_payments) || 0) + " €"
                            : parseFloat(total) + " €"}
                    </div>
                </div>
            </div>
        </div>
    )
}

YourOrder.propTypes = {
    ticket: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    total: PropTypes.string.isRequired,
}

export default YourOrder
