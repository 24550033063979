import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import { createGroup } from "../../redux/group/groupAction"
import SRModal from "../../components/modal/modal"

const BankTansfer = () => {
    const dispatch = useDispatch()
    const data = useSelector(({ groupReducer }) => groupReducer)
    const { coordinator, stripe } = useSelector(({ groupReducer }) => groupReducer)

    const registration = (payment = false) => {
        dispatch(createGroup(data, payment === true ? 2 : 2))
    }
    return (
        <SRModal title={"Bank transfer details"} text={"Bank transfer details"} className={"addMember"} classbtnName={"whitecolortext"}>
            <div className={"content content-modal"}>

                <p>
                    Bank transfers are payable to The Union and are due in EUR. If your bank transfer is not received by Friday 28 October 2022, another payment method will be required. <br />
                    Please note: your registration fee is determined by the date your payment is received, and not the date your registration was submitted.<br />
                    All bank fees are payable by the remitter/sender. As international bank transfers may incur high bank fees, we recommend registration fees are paid by card to avoid additional fees for the payer.<br />
                    Once the bank transfer is executed come back to your registration and enter the following:  Date of bank transfer, bank name and reference number.
                    This way we can easily track it at our end and switch your registration to paid in a more efficient way.
                </p>
                <Link to={"/confirmation-record"}>
                    <button className={"btn btn-warning mx-auto"} onClick={() => registration(2)}>Save group and pay by bank transfer</button>
                </Link>
            </div>
        </SRModal>
    )
}
BankTansfer.propTypes = {
    total: PropTypes.string.isRequired,
}

export default BankTansfer
