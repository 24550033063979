import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import CheckboxInput from "../welcome/checkboxInput"
import BankTransferModal from "./bankTransferModal"
import BankTransfer from "../../../components/bankTransfer/bankTransfergroup"
import CreditCardModal from "./creditCardModal"
import RefundModal from "./refundModal"
import { isDefined } from "../../../utils/data"
import { onChange, createGroup } from "../../../redux/group/groupAction"
import TermsOfSales from "../../../components/termsOfSales/termsOfSales2"

const Payment = ({ total }) => {
    const dispatch = useDispatch()
    const data = useSelector(({ groupReducer }) => groupReducer)
    const { coordinator, stripe } = useSelector(({ groupReducer }) => groupReducer)
    const { members } = useSelector(({ groupReducer }) => groupReducer)
    const { information } = useSelector(({ groupReducer }) => groupReducer)

    const registration = (payment = false) => {
        dispatch(createGroup(data, payment === true ? 1 : 0))
    }


    var new_total = 0
    isDefined(coordinator) ? isDefined(coordinator.group.group_discount) ?
        parseFloat(coordinator.group.group_discount) > 0 ?
            new_total = parseFloat(total) - parseFloat(coordinator.group.group_discount)
            : new_total = parseFloat(total)
        : new_total = parseFloat(total)
        : new_total = parseFloat(total)

    return (
        <div className={"sr-card summary-block payment"}>
            {members.length > 1 ?
                <>

                    {isDefined(coordinator) ?
                        <>
                            <div className={"summary-header"}>
                                <a className={"btn btn-sm btn-warning"} href={"https://apidev.urevent.fr/documents/group_pro_forma/" + coordinator.group.idgroups} target="_blank">Download registration summary</a><br />If you require a fiscal invoice please request it by email through: registration-theunion@urevent.fr
                            </div>
                            {coordinator.group.group_state == "Order" ?
                                <div className={"summary-body mt-3 mb-2"}>
                                    <p>If you have already made your payment by bank transfer, please indicate the references of the transfer below.</p>
                                    <div className={"col-12 input-group"}>
                                        <input type="text" className="form-control form-control-sm mb-2" placeholder="Bank transfer Reference *" name={"group_banktransfer_ref"} value="" onChange={e => dispatch(onChange(e))} />
                                    </div>
                                </div>
                                : ""
                            }
                        </>
                        :
                        <>
                            <div className={"summary-header pb-3"}>
                                After saving your group you will automatically receive a group registrations summary.<br />If you require a fiscal invoice please request it by email through: registration-theunion@urevent.fr
                            </div>
                        </>
                    }

                    <hr />
                    <div className={"summary-body"}>
                        {isDefined(coordinator) ? isDefined(coordinator.group.group_payments) ?
                            parseFloat(coordinator.group.group_payments) < parseFloat(new_total) ?
                                <>
                                    <div className={"row title-payment"}>
                                        Payment
                                    </div>
                                    <div className={"row"}>
                                        <button className={"btn btn-warning"}><CreditCardModal stripe={stripe} total={new_total} /></button>
                                    </div>
                                    <div className={"row "}>
                                        or
                                    </div>
                                    <div className={"row p-3"}>
                                        <button className={"btn btn-info btn-sm"}><BankTransfer /></button>
                                    </div>
                                    <div className={"row"}>
                                        or
                                    </div>
                                    <div className={"row p-3"}>
                                        <Link to={"/confirmation-record"}>
                                            <button className={"btn btn-warning"} onClick={() => registration()}>Save group</button>
                                        </Link>
                                    </div>
                                </>
                                :
                                parseFloat(coordinator.group.group_payments) > parseFloat(new_total) ?
                                    <>

                                        <div className={"row p-3"}>
                                            <Link to={"/confirmation-record"}>
                                                <button className={"btn btn-warning"} onClick={() => registration()}>Save group</button>
                                            </Link>
                                        </div>
                                    </>
                                    : "" : "" :
                            <>


                                <div className={"row mt-3 p-3"}>
                                    <button className={"btn btn-warning"}><CreditCardModal stripe={stripe} total={new_total} /></button>
                                </div>
                                <div className={"row "}>
                                    or
                                </div>
                                <div className={"row p-3"}>
                                    <button className={"btn btn-info btn-sm"}><BankTransfer /></button>
                                </div>
                                <div className={"row"}>
                                    or
                                </div>
                                <div className={"row p-3"}>
                                    <Link to={"/confirmation-record"}>
                                        <button className={"btn btn-warning"} onClick={() => registration()}>Save group</button>
                                    </Link>
                                </div>

                            </>
                        }
                    </div>
                    <hr />
                    <div className={"summary-footer"}>
                        <div className={"row p-3"}>
                            <TermsOfSales />
                        </div>
                    </div>

                </>
                :
                <>

                    <div className={"summary-header"}>
                        Your group must contain at least two members before it can be registered.
                    </div>
                </>}
        </div>
    )
}

Payment.propTypes = {
    total: PropTypes.string.isRequired,
}

export default Payment
