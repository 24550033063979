import React, { useEffect, useState } from "react"
import { FaEnvelope } from 'react-icons/fa'
import { useDispatch, useSelector } from "react-redux"

import "../../../../pages/union-member-single.scss"
import { onChangeByName, selectCategory } from "../../../../redux/addMember/addMemberAction"
import { checkMember, checkWctoh, checkUniqMail, getCategories } from "../../../../redux/api/apiAction"
import { isDefined } from "../../../../utils/data"
import Tab from "../../../../components/Tab/Tab"

const MemberConnection = ({ pageActive, index, onNext }) => {
    const dispatch = useDispatch()
    const { categories } = useSelector(({ apiReducer }) => apiReducer)
    const errorAPI = useSelector(({ apiReducer }) => apiReducer.login_failed)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [email, setEmail] = useState(process.env.NODE_ENV !== 'production' ? "isabella.annesi-maesano@inserm.fr" : "")
    const [validationEmail, setValidationEmail] = useState("")
    const [over, setOver] = useState(false)

    const onChange = e => {
        const { value } = e.target
        setEmail(value)
        setValidationEmail("")
    }

    useEffect(() => {
        dispatch(getCategories())
    }, [])


    const onSubmit = e => {
        e.preventDefault()

        if (email === "") {
            setValidationEmail("Enter your email")
        } else {
            setOver(true)
            dispatch(checkUniqMail(email))
                .then(res => {
                    if (res === false) {
                        setOver(false)
                        setValidationEmail("A user with this email is already registered")
                    } else {
                        dispatch(checkMember(email))
                            .then(res => {
                                if (res === true) {
                                    setEmail("")
                                    setSuccess(true)
                                    setError(false)
                                    dispatch(onChangeByName("unionMember", true))
                                    dispatch(selectCategory(categories, "UNION Member"))
                                        .then(() => onNext())
                                }
                                else {
                                    setEmail(email)
                                    setSuccess(true)
                                    setError(false)
                                    dispatch(onChangeByName("registered_mail", email))
                                    dispatch(onChangeByName("confirmEmail", email))
                                    dispatch(selectCategory(categories, "Delegate"))
                                        .then(() => onNext())
                                }
                            })
                    }
                })

        }
    }

    return (
        <Tab pageActive={pageActive} index={index}>
            <div className={"union-member-single"}>
                <div id="overlay_urevent" className={over ? "d-block" : "d-none"}>
                    <div id="text_overlay">
                        <div class="lds-ripple">
                            <div></div>
                            <div></div>
                        </div> Please wait, the database is being reviewed.
                    </div>
                </div>
                <div className={"col-12"}>
                    <p className={"title text-center"}>To start registration please enter participant email.<br />Union members have a discount automatically applied to their registration fee. <br /><br /><b>Please use the same email address provided for membership or abstract submission email</b></p>
                </div>
                <form onSubmit={onSubmit}>
                    <div className="form-row">
                        <div className={"col-1"}>
                            <label><FaEnvelope /></label>
                        </div>
                        <div className={"col-11"}>
                            <input type="email" className="form-control" placeholder="Email" name={"email"} value={email} onChange={onChange} required />
                            {validationEmail.length > 0 ?
                                <div className="invalid-tooltip">
                                    {validationEmail}
                                </div> : ""}
                        </div>
                    </div>

                    <div className={"form-row"}>
                        {error ?
                            <div className="error invalid-tooltip">
                                {isDefined(errorAPI) ? errorAPI.message : "Member not found"}
                            </div>
                            : ""}

                        {success ?
                            <div className="success valid-feedback">
                                Succeeded
                            </div>
                            : ""}
                    </div>

                    <div className={"form-footer " + (error ? "form-with-register" : "")}>
                        <button type={"submit"} className={"btn btn-info btn-round"}>Search</button>
                    </div>
                </form>
            </div>
        </Tab>
    )
}

export default MemberConnection
