import React from "react"
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux"

import "../../../singleInformation/information.scss"
import CheckboxInput from "../../../../components/form/checkboxInput"
import { validTab } from "../../../../redux/tabs/tabsAction"
import AlertCategory from "../../alertCategory/alertCategory"
import { onChangeInformation } from "../../../../redux/addMember/addMemberAction"
import Tab from "../../../../components/Tab/Tab"

const Information = ({ pageActive, index, onPrev, onNext }) => {
    const dispatch = useDispatch()
    const { information } = useSelector(({ addMemberReducer }) => addMemberReducer)
    const { registered_interest, registered_intouch } = information

    const onSubmit = (e = null) => {
        const form = e.currentTarget

        if (registered_interest.value.length > 0) {
            dispatch(validTab("informationTab"))
            onNext()
        }
        else {

        }

        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        }

        //dispatch(submitInformation(user, values, onNext))
    }

    const getValue = (name, isInterest = true) => {
        if (isInterest)
            return registered_interest.value.indexOf(name) !== -1
        else
            return registered_intouch.value.indexOf(name) !== -1
    }

    return (
        <Tab pageActive={pageActive} index={index}>
            <div className={"content information"}>
                <AlertCategory />

                <form onSubmit={onSubmit} className={"needs-validation"} noValidate>
                    <div className="form-group">
                        <div className="row mx-auto">
                            <div className={"col-12"}><p className={"title"}>Main areas of interest (tick one or more)</p></div>

                            <div className={"col-12 col-md-6 col-lg-4"}>
                                <CheckboxInput placeholder={"Air pollution"} onChange={e => dispatch(onChangeInformation(e))} name={"Air pollution"} value={getValue("Air pollution")} />
                                <CheckboxInput placeholder={"Asthma"} onChange={e => dispatch(onChangeInformation(e))} name={"Asthma"} value={getValue("Asthma")} />
                                <CheckboxInput placeholder={"Child TB"} onChange={e => dispatch(onChangeInformation(e))} name={"Child TB"} value={getValue("Child TB")} />
                                <CheckboxInput placeholder={"Civil society"} onChange={e => dispatch(onChangeInformation(e))} name={"Civil society"} value={getValue("Civil society")} />
                                <CheckboxInput placeholder={"Clinical research"} onChange={e => dispatch(onChangeInformation(e))} name={"Clinical research"} value={getValue("Clinical research")} />
                                <CheckboxInput placeholder={"Clinical care"} onChange={e => dispatch(onChangeInformation(e))} name={"Clinical care"} value={getValue("Clinical care")} />
                                <CheckboxInput placeholder={"Communications/social media"} onChange={e => dispatch(onChangeInformation(e))} name={"Communications/social media"} value={getValue("Communications/social media")} />
                                <CheckboxInput placeholder={"Conferences and events"} onChange={e => dispatch(onChangeInformation(e))} name={"Conferences and events"} value={getValue("Conferences and events")} />
                                <CheckboxInput placeholder={"COPD"} onChange={e => dispatch(onChangeInformation(e))} name={"COPD"} value={getValue("COPD")} />
                                <CheckboxInput placeholder={"COVID-19"} onChange={e => dispatch(onChangeInformation(e))} name={"COVID-19"} value={getValue("COVID-19")} />
                                <CheckboxInput placeholder={"Diagnostics"} onChange={e => dispatch(onChangeInformation(e))} name={"Diagnostics"} value={getValue("Diagnostics")} />
                                <CheckboxInput placeholder={"Epidemiology"} onChange={e => dispatch(onChangeInformation(e))} name={"Epidemiology"} value={getValue("Epidemiology")} />
                                <CheckboxInput placeholder={"Global health"} onChange={e => dispatch(onChangeInformation(e))} name={"Global health"} value={getValue("Global health")} />
                                <CheckboxInput placeholder={"Health policy"} onChange={e => dispatch(onChangeInformation(e))} name={"Health policy"} value={getValue("Health policy")} />
                            </div>
                            <div className={"col-12 col-md-6 col-lg-4"}>
                                <CheckboxInput placeholder={"HIV, diabetes and other comorbidities"} onChange={e => dispatch(onChangeInformation(e))} name={"HIV, diabetes and other comorbidities"} value={getValue("HIV, diabetes and other comorbidities")} />
                                <CheckboxInput placeholder={"Human rights"} onChange={e => dispatch(onChangeInformation(e))} name={"Human rights"} value={getValue("Human rights")} />
                                <CheckboxInput placeholder={"Imaging"} onChange={e => dispatch(onChangeInformation(e))} name={"Imaging"} value={getValue("Imaging")} />
                                <CheckboxInput placeholder={"Immunology"} onChange={e => dispatch(onChangeInformation(e))} name={"Immunology"} value={getValue("Immunology")} />
                                <CheckboxInput placeholder={"Implementation research"} onChange={e => dispatch(onChangeInformation(e))} name={"Implementation research"} value={getValue("Implementation research")} />
                                <CheckboxInput placeholder={"Infection prevention and control"} onChange={e => dispatch(onChangeInformation(e))} name={"Infection prevention and control"} value={getValue("Infection prevention and control")} />
                                <CheckboxInput placeholder={"Laboratory services"} onChange={e => dispatch(onChangeInformation(e))} name={"Laboratory services"} value={getValue("Laboratory services")} />
                                <CheckboxInput placeholder={"Lung health"} onChange={e => dispatch(onChangeInformation(e))} name={"Lung health"} value={getValue("Lung health")} />
                                <CheckboxInput placeholder={"Membership"} onChange={e => dispatch(onChangeInformation(e))} name={"Membership"} value={getValue("Membership")} />
                                <CheckboxInput placeholder={"Microbiology"} onChange={e => dispatch(onChangeInformation(e))} name={"Microbiology"} value={getValue("Microbiology")} />
                                <CheckboxInput placeholder={"Modelling"} onChange={e => dispatch(onChangeInformation(e))} name={"Modelling"} value={getValue("Modelling")} />
                                <CheckboxInput placeholder={"New drugs & treatment regimens"} onChange={e => dispatch(onChangeInformation(e))} name={"New drugs & treatment regimens"} value={getValue("New drugs & treatment regimens")} />
                                <CheckboxInput placeholder={"Non-communicable diseases"} onChange={e => dispatch(onChangeInformation(e))} name={"Non-communicable diseases"} value={getValue("Non-communicable diseases")} />
                                <CheckboxInput placeholder={"Non- tuberculosis mycobacterium"} onChange={e => dispatch(onChangeInformation(e))} name={"Non- tuberculosis mycobacterium"} value={getValue("Non- tuberculosis mycobacterium")} />
                            </div>
                            <div className={"col-12 col-md-6 col-lg-4"}>
                                <CheckboxInput placeholder={"Nursing"} onChange={e => dispatch(onChangeInformation(e))} name={"Nursing"} value={getValue("Nursing")} />
                                <CheckboxInput placeholder={"Paediatrics"} onChange={e => dispatch(onChangeInformation(e))} name={"Paediatrics"} value={getValue("Paediatrics")} />
                                <CheckboxInput placeholder={"People-centered care"} onChange={e => dispatch(onChangeInformation(e))} name={"People-centered care"} value={getValue("People-centered care")} />
                                <CheckboxInput placeholder={"Pharmaceuticals"} onChange={e => dispatch(onChangeInformation(e))} name={"Pharmaceuticals"} value={getValue("Pharmaceuticals")} />
                                <CheckboxInput placeholder={"Pneumonia (adult and child)"} onChange={e => dispatch(onChangeInformation(e))} name={"Pneumonia (adult and child)"} value={getValue("Pneumonia (adult and child)")} />
                                <CheckboxInput placeholder={"Post TB lung health"} onChange={e => dispatch(onChangeInformation(e))} name={"Post TB lung health"} value={getValue("Post TB lung health")} />
                                <CheckboxInput placeholder={"Prevention"} onChange={e => dispatch(onChangeInformation(e))} name={"Prevention"} value={getValue("Prevention")} />
                                <CheckboxInput placeholder={"Radiography"} onChange={e => dispatch(onChangeInformation(e))} name={"Radiography"} value={getValue("Radiography")} />
                                <CheckboxInput placeholder={"TB (DR and DS-TB)"} onChange={e => dispatch(onChangeInformation(e))} name={"TB (DR and DS-TB)"} value={getValue("TB (DR and DS-TB)")} />
                                <CheckboxInput placeholder={"Tobacco control"} onChange={e => dispatch(onChangeInformation(e))} name={"Tobacco control"} value={getValue("Tobacco control")} />
                                <CheckboxInput placeholder={"Vaccine development"} onChange={e => dispatch(onChangeInformation(e))} name={"Vaccine development"} value={getValue("Vaccine development")} />
                                <CheckboxInput placeholder={"Webinars and courses"} onChange={e => dispatch(onChangeInformation(e))} name={"Webinars and courses"} value={getValue("Webinars and courses")} />
                                <CheckboxInput placeholder={"Zoonotic diseases"} onChange={e => dispatch(onChangeInformation(e))} name={"Zoonotic diseases"} value={getValue("Zoonotic diseases")} />
                            </div>
                        </div>
                        {information.registered_interest.error !== "" ?
                            <div className="invalid-tooltip">{information.registered_interest.error}</div>
                            : ""}
                    </div>
                    <div className="form-group">
                        <div className={"form-row"}>
                            <div className={"col-12 text-left"}>
                                <CheckboxInput placeholder={"I would like to be kept up to date about The Union’s latest work and services."}
                                    onChange={e => dispatch(onChangeInformation(e, false))}
                                    name={"accept_com"}
                                    value={getValue("accept_com", false)} />

                            </div>
                        </div>
                    </div>
                </form>

                <div className={"content-footer"}>
                    <button className={"sr-btn-next btn btn-secondary"} onClick={onPrev}>Previous</button>
                    <button className={"sr-btn-next btn btn-info"} disabled={!(registered_interest.value.length > 0)} onClick={onSubmit}>Next</button>
                </div>
            </div>
        </Tab>
    )
}

Information.propTypes = {
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
}

export default Information
