import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import "./summary/summary.scss"
import YourOrder from "./summary/your-order"
import Payment from "./summary/payment"
import { isDefined } from "../../utils/data"
import { onChange } from "../../redux/group/groupAction"
import CheckboxInput from "./welcome/checkboxInput"
import CheckboxInputindiv from "./welcome/checkboxInputindiv"

const Summary = ({ prevTab }) => {
    const dispatch = useDispatch()
    const { members, coordinator } = useSelector(({ groupReducer }) => groupReducer)
    const [total, setTotal] = useState(0)
    const [totaldiscount, setTotaldiscount] = useState(0)
    const { information } = useSelector(({ groupReducer }) => groupReducer)

    useEffect(() => {
        setTotal(0)
        members.forEach(member => {
            setTotal(prev => prev + (isDefined(member.amount) ? member.amount : (parseFloat(member.registered.registered_invoice))))
        })
    }, [members])



    return (
        <div className={"content summary"}>
            <p className={"important-infos"}>Summary and payment</p>
            <div className={information.group_cgv.value === false || information.group_cgv2.value === false ? "" : "d-none"}>
                <div className={"row"}>
                    <div className={"col-lg-12"}>
                        <div className={"sr-card summary-block payment"}>
                            <div className={"summary-body mb-2 privacy_text"}>
                                <p>How we use your data</p>
                                <p>(Please tick the following on behalf of the participants in your group)<br /></p>
                                <p>The Union will use the personal data collected from this form only in direct relation to your attendance at this year’s virtual event of The Union World Conference on Lung Health. For example, your personal data will be necessary for you to be able to access program updates or the mobile app.<br />In line with The Union’s Privacy Policy and following the EU GDPR, you can exercise your rights regarding your personal data (especially access, rectification, erasure, and portability) at any time by emailing <a href="mailto:privacy@theunion.org">privacy@theunion.org</a>.</p>
                                {!isDefined(coordinator) ?
                                    <CheckboxInputindiv placeholder={" I understand this is how The Union will use my data in relation to this virtual event"}
                                        onChange={e => dispatch(onChange(e, true))}
                                        name={"group_cgv"}
                                        value={information.group_cgv.value}
                                        required={true} />
                                    : ""
                                }

                                <p className={"mt-4"}>When attending online events, if you accept a connection/meeting request or exchange messages in the chat with one exhibitor, your full contact information (title, name, last name, email, city, country, organisation, department, function) will be shared with this exhibitor so that they can engage with you, both during and after the event.<br />If you attend a sponsored session on the online platform, you accept that your full contact details (as detailed above) will be shared directly with the sponsor and/or exhibitor and that they may follow-up in line with your indication of interest in their products.
                                    If you do not wish to share your personal data with exhibitors or sponsors, as described above, you understand and accept that it will be up to you to refuse a connection or meeting request from an exhibitor, or not to attend a sponsored session.</p>
                                {!isDefined(coordinator) ?
                                    <CheckboxInputindiv placeholder={" I understand this is how The Union will share my personal data with sponsors or exhibitors for this virtual event."}
                                        onChange={e => dispatch(onChange(e, true))}
                                        name={"group_cgv2"}
                                        value={information.group_cgv2.value}
                                        required={true} />
                                    : ""
                                }
                            </div></div>
                    </div>
                </div>
            </div>
            <div className={information.group_cgv.value === false || information.group_cgv2.value === false ? "d-none" : "d-block"}>
                <div className={"row"}>
                    <div className={"col-lg-6"}>
                        <YourOrder members={members} total={total} />
                    </div>
                    <div className={"col-lg-6"}>
                        <Payment total={total} />
                    </div>
                </div>
            </div>
            <div className={"content-footer"}>
                <button className={"sr-btn-next btn btn-secondary"} onClick={prevTab}>Previous</button>
            </div>
        </div>
    )
}

Summary.propTypes = {
    prevTab: PropTypes.func.isRequired,
}

export default Summary
