import React, { useState } from "react"
import "./non-member-single.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import Tabs from "../containers/group/tabs/groupTabs"
import About from "../containers/group/about"
import Information from "../containers/group/information"
import Members from "../containers/group/members/members"
import Summary from "../containers/group/summary"
import YourData from "../containers/group/yourData"

const GroupPage = () => {
    const [tabIndex, setTabIndex] = useState(0)

    const onSelect = index => {
        setTabIndex(index)
    }

    const prevTab = () => setTabIndex(prev => prev - 1);
    const nextTab = () => setTabIndex(prev => prev + 1);

    return (
        <Layout main={false} className={"non-member-single"}>
            <SEO title="Registration" />
            <Header />
            <Tabs
                index={tabIndex}
                onSelect={onSelect}
                about={<About nextTab={nextTab} />}
                information={<Information prevTab={prevTab} nextTab={nextTab} />}
                members={<Members prevTab={prevTab} nextTab={nextTab} />}
                yourData={<YourData prevTab={prevTab} nextTab={nextTab} />}
                summary={<Summary prevTab={prevTab} />}
            />
        </Layout>
    )
}

export default GroupPage
